<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Market profile</h1>
        <p>Explore the different policies in the market of your choice
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-form inline>
          Select market: <b-form-select class="mr-4 ml-2" v-model="countrySelected" :options="countryOptions" size="lg" style="font-weight: 900"></b-form-select>
        </b-form>
      </b-col>
      <b-col style="text-align: right;" v-if="$config.BUILD === 'full'">
        <b-button @click="download">Download {{ countrySelected }} policies</b-button>
      </b-col>
    </b-row>
    <b-row class="mb-4 shadow">
      <b-col lg="2" class="py-3 px-0" style="background: #f9f9f9; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <div>
          <div class="accordion" role="tablist">
            <div v-for="(row, index) in initiativesByArea" :key="'topicHeading-' + index">
              <div header-tag="header" role="tab">
                <div class="px-3 py-3" style="font-size: 1.2em" v-b-toggle="'accordion-' + index"><strong>{{row.area.name}}</strong></div>
              </div>
              <b-collapse :id="'accordion-' + index" :visible="index === 0" accordion="my-accordion" role="tabpanel">
                <div>
                  <div v-for="(initiative, index2) in row.initiatives" :key="'initiative-' + index2">
                    <div class="px-3 py-3" style="cursor: pointer;" :id="'initiative' + initiative.id" @click="findInitiative(initiative)">{{ initiative.impactpolicy.name }}</div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="7" v-if="initiative" class="py-3" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
        <b-container class="py-3">
          <b-row>
            <b-col>
              <h5>{{ initiative.name }}</h5>
            </b-col>
            <b-col style="text-align: right;"><b-button variant="outline-primary" @click="compareCountry">Compare market</b-button></b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="initiative.impactexperts.length > 0">
                <div class="mt-4"><strong>Specialists</strong></div>
                <div v-for="expert in initiative.impactexperts" :key="'expert'+expert.id">
                  <div v-if="$config.BUILD === 'full'">
                    <router-link :to="{ name: 'Expert', params: { id: expert.id }}">{{expert.name}}</router-link>
                  </div>
                  <div v-else>{{expert.name}}</div>
                </div>
              </div>
              <div class="my-4"><strong>{{ initiative.headline }}</strong></div>
              <div v-html="initiative.description"></div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="3" v-if="initiative" class="py-3 px-0" style="background: white; height: 60vh; overflow-y: auto;  overflow-x: auto;">
          <div class="px-3 py-3">
            <div>
              <div v-if="initiative.impactpolicy_id > 0 && !initiative.impactpolicy.overview">
                <policy-initiative-details :item="initiative" />
              </div>
            </div>
            <div v-if="initiative.impactinitiativeslinks.length > 0">
              <div class="mt-5"><strong>External links</strong></div>
              <div v-for="link in initiative.impactinitiativeslinks" :key="'link'+link.id" class="my-2">
                <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
              </div>
            </div>
            <div v-if="!loadingNews && news.length > 0">
              <div class="mt-5"><strong>News - external links</strong></div>
              <div v-for="item in news" :key="'news'+item.id" class="my-3">
                <div><a :href="item.link">{{item.name}}</a></div>
                <div><small>{{item.publisher}} - {{moment(item.date).format('YYYY-MM-DD')}}</small></div>
              </div>
            </div>
            <div class="my-3" v-if="loadingNews">
              <b-spinner label="checking news..." />
              loading news...
            </div>
          </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
// import ImpactpolicyAccordion from '@/components/ImpactpolicyAccordion'
// import ImpactpolicySummary from '@/components/ImpactpolicySummary'
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetailsRows'

// create delay function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'CountryPolicies',
  components: {
    // ImpactpolicyAccordion
    // ImpactpolicySummary
    PolicyInitiativeDetails
  },
  computed: {
    countryOptions: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      results.sort()
      return results
    },
    countrySelected: {
      get () {
        return this.$store.state.countryCPRD1
      },
      set (payload) {
        this.$store.commit('setCountryCPRD1', payload)
      }
    },
    initiatives: function () {
      const country = this.$route.params.country
      let results = []
      const all = this.$store.state.initiativesPolicies
      all.forEach(x => {
        if (x.country === country) {
          results.push(x)
        }
      })
      results = _.orderBy(results, ['impactpolicy.name'], ['asc'])
      return results
    },
    initiativesByArea: function () {
      const results = []
      const all = this.initiatives
      this.areas.forEach(area => {
        let row = {}
        row = {
          area: area,
          area_id: area.id,
          initiatives: []
        }
        all.forEach(initiative => {
          if (initiative.impactpolicy.taxonomy.length > 0) {
            initiative.impactpolicy.taxonomy.forEach(t => {
              if (t.id === area.id) {
                row.initiatives.push(initiative)
              }
            })
          }
        })
        results.push(row)
      })
      results.forEach(row => {
        const initiatives = _.orderBy(row.initiatives, ['impactpolicy.overview', 'name'], ['desc', 'asc'])
        row.initiatives = initiatives
      })
      return results
    },
    initiativesSummary: function () {
      const all = this.initiatives
      const ids = [4]
      const results = []
      all.forEach(policy => {
        if (ids.find(id => policy.impactpolicy_id === id)) {
          results.push(policy)
        }
      })
      return results
    },
    areas: function () {
      const all = this.initiatives
      let results = []
      all.forEach(i => {
        if (i.impactpolicy.taxonomy.length > 0) {
          i.impactpolicy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy)
          })
        }
      })
      results = _.uniqBy(results, 'id')
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard: " + this.$route.params.country
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    await delay(100)
    if (this.$route.params.country) {
      this.countrySelected = this.$route.params.country
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
    } else if (window.localStorage.getItem('frmCloudCountrySelected')) {
      this.countrySelected = window.localStorage.getItem('frmCloudCountrySelected')
    } else {
      this.countrySelected = this.countryOptions[0]
    }
    await delay(100)
    if (this.$route.params.initiative) {
      const initiative = this.initiatives.find(x => x.id === parseInt(this.$route.params.initiative))
      if (initiative) {
        this.findInitiative(initiative)
      } else {
        this.findInitiative(this.initiativesByArea[0].initiatives[0])
      }
    } else {
      this.findInitiative(this.initiativesByArea[0].initiatives[0])
    }
  },
  data () {
    const data = {
      area: null,
      initiative: null,
      loadingNews: false,
      moment: moment,
      news: [],
      staticDataProperties: [
        { name: 'Areas', property: 'area', graph: 'area', tree: true },
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Policies', property: 'impactpolicy', graph: 'impactpolicy', tree: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true }
      ]
    }
    return data
  },
  methods: {
    compareCountry: function () {
      this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected, country2: 'null' } })
    },
    download: function () {
      const filterSelected = this.$store.state.filterSelected
      filterSelected.cprd = {}
      this.staticDataProperties.forEach(row => {
        filterSelected.cprd[row.property] = []
      })
      filterSelected.cprd.country = [this.countrySelected]
      this.$store.commit('setFilterSelected', filterSelected)
      this.$router.push({ name: 'ImpactPoliciesDownload' })
    },
    findInitiative: function (initiative) {
      if (this.initiative) {
        const el = document.getElementById('initiative' + this.initiative.id)
        if (el) {
          el.setAttribute('style', 'background-color: #f9f9f9; cursor: pointer; font-weight: normal;')
        }
      }
      this.initiative = initiative
      this.loadNews()
      this.$router.push({ name: 'ImpactPoliciesCountry', params: { country: this.countrySelected, initiative: initiative.id } })
      const el2 = document.getElementById('initiative' + this.initiative.id)
      if (el2) {
        el2.setAttribute('style', 'background-color: #aceaff; font-weight: bold;')
      }
    },
    loadNews: async function () {
      this.loadingNews = true
      try {
        document.title = this.initiative.name + ' - ' + this.initiative.country
        this.news = await this.$Amplify.API.get('cosmos', `/news/country/${this.initiative.country}/policy/${this.initiative.impactpolicy_id}`)
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  },
  watch: {
    '$route.params.country': function () {
      this.countrySelected = this.$route.params.country
      document.title = "Climate Policy Dashboard: " + this.$route.params.country
    },
    'countrySelected': async function () {
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
      if (this.countrySelected !== this.$route.params.country) {
        this.$router.push({ name: 'ImpactPoliciesCountry', params: { country: this.countrySelected } })
        this.initiative = null
        this.showInitiatives = false
        await delay(250)
        this.findInitiative(this.initiativesByArea[0].initiatives[0])
      }
    }
  }
}
</script>

<style>
</style>
