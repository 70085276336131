<template>
<div>
    <div class="policy-initiatives-details">
          <div class="mb-2 pb-2" v-for="field in schema.fields" :key="schema.id + '-' + field.name">
            <div v-if="!field.skip">
              <div v-if="field.type === 'number'">
                <div class="font-weight-bold">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></div>
                <div>
                  <span v-if="item.policydata[field.name]">
                    <span v-if="field.formatter">{{field.formatter(item.policydata)}}</span>
                    <span v-else>{{item.policydata[field.name]}}</span>
                  </span>
                  <span v-else>Not available</span>
                </div>
              </div>
              <div v-if="field.type === 'fx'">
                <div class="font-weight-bold">{{  field.label }}<span v-if="item.policydata.help"><info-circle v-if="item.policydata.help[field.name]" :message="item.policydata.help[field.name]" /></span></div>
                <div>
                  <span v-if="item.policydata[field.name]">
                    <span v-if="field.formatter">{{field.formatter(item.policydata)}}</span>
                    <span v-else>{{item.policydata[field.name]}}</span>
                  </span>
                  <span v-else>Not available</span>
                </div>
              </div>
              <div v-if="field.type === 'boolean'">
                <div class="font-weight-bold">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></div>
                <div v-if="item.policydata[field.name] === 'true'">Yes</div>
                <div v-else>No</div>
              </div>
              <div v-if="field.type === 'select'">
                <div class="font-weight-bold">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></div>
                <div v-if="field.formatter">{{field.formatter(item.policydata)}}</div>
                <div v-else>{{item.policydata[field.name]}}</div>
              </div>
              <div v-if="field.type === 'checkbox' && item.policydata[field.name]">
                <div class="font-weight-bold">{{  field.label }}<info-circle v-if="field.help" :message="field.help" /></div>
                <div v-if="item.policydata[field.name].length > 0"><span v-for="item in item.policydata[field.name]" :key="'coverage' + item">{{item}}, </span></div>
                <div v-else>No data</div>
              </div>
            </div>
          </div>
    </div>
</div>
</template>

<script>
import policysets from '@/libs/policysets'
import InfoCircle from '@/components/InfoCircle.vue'

export default {
  name: 'PolicyInitiativeDetails',
  props: ['item'],
  components: {
    InfoCircle
  },
  computed: {
    schema () {
      // find record from policysets based on item.impactpolicy_id
      const record = policysets.find(item => item.id === this.item.impactpolicy_id)
      // remove all rows from record where skip === true
      record.fields = record.fields.filter(field => !field.skip)
      return record
    }
  },
  data () {
    const data = {
    }
    return data
  }
}
</script>

<style scoped>
.policy-initiatives-details {
  padding: 15px;
  border: none;
  background-color: #def4ff;
}
.policy-initiatives-details > div:not(:last-child) {
    border-bottom: 2px solid #b4cad8;
}
</style>
